import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title="404 | Motherepos"
        desc="404 page Motherepos"
        keywords="404 page"
      />
    </Layout>
  )
}

export default NotFoundPage
